import React from 'react';
import { Page, Text, View, Document, StyleSheet, Link } from '@react-pdf/renderer';
import moment from 'moment';


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }, 
  message: {
    marginTop: 7,
    paddingHorizontal: 2,
    paddingVertical: 2,
    fontSize: '9px'
  },
  image: {
    height: '150px',
    width: '150px',
  }
});

// Create Document Component
export function MyDocument({mensajes}){


  //console.log("testDocument", mensajes)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {
          
          mensajes.map((mensaje, index) => {
            let date = moment(mensaje.date.toDate()).format('L, h:mm a');
            // console.log(date)
            return (

                <View key={mensaje.msgId} style={styles.message}>
                  <Text>{date}, {mensaje.position === "left" ? "Agente:" : "Yo:"}</Text>
                  {mensaje.type === "audio" ? (
                    <>
                      <Link src={mensaje.url}>Audio</Link>
                    </>
                  ) : mensaje.type === "text" ? (
                    <>
                      <Text>{mensaje.text}</Text>
                    </>
                  ) : mensaje.type === "photo" ? (
                    <>
                      {/* <Image
                        // src= {mensaje.url}
                        // src={{
                        //   uri: mensaje.url,
                        //   method: "GET",
                        //   headers: {"Access-Control-Allow-Origin" : "*"},
                        //   body: "",
                        // }}
                      {/* <Text>Imagen</Text>
                        style={styles.image}
                      /> */}
                       
                      <Link src={mensaje.url}>Imagen</Link>
                    </>
                  ) : mensaje.type === "file" ? (
                    <>
                      <Link src={mensaje.url}>{mensaje.text}</Link>

                    </>
                  ) : null}
                </View>

            );
          }) 
          
        }
        </View>
      </Page>
    </Document>
  );
} 