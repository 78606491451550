import React, { createContext, useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { db, auth, provider } from "../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import PropTypes from "prop-types";

// import { getAuth, signInAnonymously } from "firebase/auth";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const [token, setToken] = useState(null);
  const [conversaciones, setConversaciones] = useState([]);
  const [mensajes, setMensajes] = useState([]);
  // const [websocket, setWebsocket] = useState(null);
  // const [showChat, setShowChat] = useState(false)
  const [ultimoMsje, setUltimoMsje] = useState(null);
  const [ultimaConv, setUltimaConv] = useState(null);
  const [widgetState, setWidgetState] = useState(0);
  const [showHelp, setShowHelp] = useState(true);
  const [soundActive, setSoundActive] = useState(true);
  const [cantContNewMsg, setCantContNewMsg] = useState(0);

  const [newMsgReceived, setNewMsgReceived] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [otherChannelInfo, setOtherChannelInfo] = useState(0);

  const [actualLocation, setActualLocation] = useState(true)
  //chatProvider ejemplo

  const dataUsuario = { uid: null, estado: null };
  const [usuario, setUsuario] = useState(dataUsuario);
  const [loadingSpinner, setLoadingSpinner] = useState(false)
  const [openModalImg, setOpenModalImg] = useState(false)
  const [imgModal, setImgModal] = useState("")

  // const [mensajes, setMensajes] = React.useState([])

  const detectarUsuario = () => {
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        // console.log('test')
      })
      .catch((error) => {
        // var errorCode = error.code;
        // var errorMessage = error.message;
        console.log("errorCode", error.code);
        //console.log(errorMessage)
        // ...
      });
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUsuario({ uid: user.uid, estado: true });
        //cargarMensajes();
        // console.log('exito', user.uid)
      } else {
        setUsuario({ uid: null, estado: false });
        // console.log('setUsuario')
      }
    });
  };

  React.useEffect(() => {
    document.addEventListener("visibilitychange", function (event) {
      if (document.hidden) {
        setActualLocation(false);
      } else {
        setActualLocation(true);
      }
    });
  }, [actualLocation]); 

  React.useEffect(() => {
    detectarUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      setIsMobile(true);
      // console.log(isMobile)
    } else {
      // false for not mobile device
      setIsMobile(false);
      // console.log(isMobile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ingresoUsuario = async () => {
    try {
      await auth.signInWithPopup(provider);
    } catch (error) {
      // console.log(error);
    }
  };

  const cerrarSesion = () => {
    auth.signOut();
  };

  const getCONFIG = async (tokenurl) => {
    let collection = db.collection("config");
    //  console.log(tokenurl)

    collection.where("token", "==", tokenurl).onSnapshot((query) => {
      //  console.log(query.docs)
      if (query.docs.length > 0) {
        const arrayConfig = query.docs.map((item) => item.data());
        if (arrayConfig != null) {
          //console.log(arrayConfig[0].data);
          setConfig(JSON.parse(arrayConfig[0].data));
          //console.log(arrayConfig)
        }
      } else {
        setConfig(null);
      }
    });
    // console.log(documento)
  };
  const saveConverLocalStorage = async (conversacion) => {
    try {
      await db.collection("conversations").add(conversacion);
    } catch (error) {
      //console.log(error);
    }
  };

  var unsubscribeConversations = null;
  var unsubscribeMessages = null;
  var unsubscribeNewMessages = null;

  const cargarConversaciones = () => {
    // console.log('token', token)
    // console.log('usuario', usuario)

    try {
      //console.log('unsubscribeConversations', unsubscribeConversations)
      if (unsubscribeConversations != null) {
        unsubscribeConversations();
      }
    } catch {}

    unsubscribeConversations = db
      .collection("conversations")
      .where("token", "==", token)
      .where("userId", "==", usuario.uid)
      .orderBy("date")
      .onSnapshot((query) => {
        const arrayConversaciones = query.docs.map((item) => item.data());
        let cant = 0;

        arrayConversaciones.forEach((c) => {
          if (c.unread > 0) {
            cant++;
          }
        });
        setCantContNewMsg(cant);

        setConversaciones(arrayConversaciones);
        //console.log(arrayConversaciones)
      });

    // console.log('unsubscribeConversations', unsubscribeConversations)
  };

  const conversacion_setAsRead = async (id) => {
    // console.log('update id', id)
    // console.log('token', token)
    // console.log('usuario id', usuario.uid)

    const converToUpdate = await db
      .collection("conversations")
      .where("token", "==", token)
      .where("userId", "==", usuario.uid)
      .where("id", "==", id)
      .get();

    // console.log('converToUpdate', converToUpdate)

    converToUpdate.forEach((doc) => {
      doc.ref.update({
        unread: 0,
      });
    });
  };

  const eliminarConversaciones = async () => {
    try {
      // console.log('ultimaConv ---->', ultimaConv)

      var deleteConver = await db
        .collection("conversations")
        .where("token", "==", token)
        .where("userId", "==", usuario.uid)
        .where("id", "==", ultimaConv.id)
        .get();

      //console.log('deleteConver ---->', deleteConver)

      deleteConver.forEach((doc) => {
        doc.ref.delete();
      });
    } catch (error) {
      // console.log('eliminarConversaciones ---->', error)
    }
  };
  const subscribeNewMessages = () => {
    //console.log('subscribeNewMessages')

    try {
      if (unsubscribeNewMessages != null) {
        unsubscribeNewMessages();
      }
    } catch {}

    //console.log('subscribeNewMessages')
    var DateTimeLimit = new Date();
    unsubscribeNewMessages = db
      .collection("messages")
      .where("token", "==", token)
      .where("userId", "==", usuario.uid)
      .where("position", "==", "left")
      .orderBy("date")
      .limitToLast(1)
      .onSnapshot((query) => {
        if (query) {
          const newMSG = query.docs.map((item) => item.data());
          if (newMSG.length > 0 && newMSG[0].date.toDate() >= DateTimeLimit) {
            setNewMsgReceived(newMSG[0]);
          }
        }
      });
  };
  const cargarMensajes = () => {
    //console.log('cargarMensajes', unsubscribeMessages)

    if (ultimaConv == null) {
      return;
    }

    try {
      if (unsubscribeMessages != null) {
        //console.log('unsubscribeMessages', unsubscribeMessages)
        unsubscribeMessages();
      }
    } catch {}

    unsubscribeMessages = db
      .collection("messages")
      .where("token", "==", token)
      .where("userId", "==", usuario.uid)
      .where("idConvTrii", "==", ultimaConv.idConvTrii)
      .orderBy("date")
      .onSnapshot((query) => {
        if (query) {
          const arrayMensajes = query.docs.map((item) => item.data({ serverTimestamps: 'estimate' }));
          const arrayMensajes2 = arrayMensajes.filter((m) => m.idConvTrii != 0);
          // arrayMensajes2.forEach(m => {
            //   m.buttonsOb = JSON.parse(m.buttons);
            // })
          for (let index = 0; index < arrayMensajes2.length; index++) {
            try {
              const element = arrayMensajes2[index];
              if(element.buttons != null && element.buttons != ""){
                arrayMensajes2[index].buttonsOb = JSON.parse(element.buttons);
              }
            }
            catch (er) {
              console.error(er);
            }
          }
          // console.log('arrayMensajes2', arrayMensajes2);
          setMensajes(arrayMensajes2);
        }

        //console.log(arrayMensajes)
      });
  };
  const agregarMensajes = async (ultimoMsje) => {
    // console.log("ultimomsg", ultimoMsje)
    try {
      // console.log("local date", new Date())
      // console.log("server date", firebase.firestore.FieldValue.serverTimestamp())

      ultimoMsje.date = firebase.firestore.FieldValue.serverTimestamp()

      await db.collection("messages").add(ultimoMsje);
      //console.log('test Save ---->>', addMsg)
    } catch (error) {
      //console.log(error);
    }
  };

  const grabarUltimoMsg = (mensaje) => {
    let grabar = false;
    if (conversaciones != null) {
      conversaciones.forEach((conver) => {
        if (conver.id === ultimaConv.id) {
          // console.log('ultimoMsje change map conver');

          conver.lastMessage = mensaje;
          if (conver.lastMessage.type === "text") {
            conver.lastMessage = mensaje.text;
          }
          if (conver.lastMessage.type === "photo") {
            conver.lastMessage = "Foto";
          }
          if (conver.lastMessage.type === "file") {
            conver.lastMessage = "Archivo";
          }
          if (conver.lastMessage.type === "audio") {
            conver.lastMessage = "Audio";
          }
          // console.log('ultimoMsje', conver.lastMessage);;
          grabar = true;
        }
      });
    }
    if (grabar) {
      saveConversations(conversaciones);
    }
  };

  //fin chatProvider ejemplo
  const getUSER = () => {
    var sessionData = JSON.parse(localStorage.getItem("sessionData"));
    // console.log('sesion',sessionData)
    if (sessionData === null) {
      let sessionDataCreate = {
        sessionUser: uuidv4(),
        sessionToken: uuidv4(),
      };
      sessionData = sessionDataCreate;
      localStorage.setItem("sessionData", JSON.stringify(sessionDataCreate));
    }
    // console.log('sessionData', sessionData)
    return sessionData;
  };

  const getCONVERSEL = () => {
    return ultimaConv;
  };

  // useEffect(() => {
  //   let WidgetStatus = localStorage.getItem("widgetState");
  //   WidgetStatus == null ? setWidgetState(0) : setWidgetState(WidgetStatus);
  // }, []);

  const handleWidgetClick = useCallback(() => {
    if (config.callout) {
      config.callout = false;
    }
    // console.log(config.callout);
    if (config.disenoWidget === "circleText") {
      window.parent.postMessage({ action: "closeTriiWidgetText" }, "*");
    } else if (config.disenoWidget === "circle") {
      window.parent.postMessage({ action: "closeTriiWidget" }, "*");
    }

    setMustGrow(false);
    localStorage.setItem(`widgetState_${token}`, 0);
    widgetStateView(0);
    //   setWidgetState(0);
  }, [config, token]);

  const openWidgetContainer = () => {
    widgetStateView(1);
  };

  const handleCloseHelp = (e) => {
    setShowHelp(false);
    if (e != null) e.stopPropagation();
  };

  const widgetStateView = useCallback((numRender) => {
    if (numRender === 0) {
      localStorage.setItem(`widgetState_${token}`, numRender);
      setWidgetState(numRender);
    }
    if (numRender > 0) {
      setWidgetState(numRender);
      localStorage.setItem(`widgetState_${token}`, numRender);
      //   localStorage.setItem("widgetState", 1);
    }
  }, [token]);

  const handleConversations = (newConver) => {
    setConversaciones((conversaciones) => [...conversaciones, newConver]);
  };
  const saveConversations = (conversacionesList) => {
    let conversJSON = JSON.stringify(conversacionesList);
    localStorage.setItem("conversaciones", conversJSON);
  };
  const [mustGrow, setMustGrow] = useState(false);
  useEffect(() => {
    widgetState > 0 && setMustGrow(true);
  }, [widgetState]);
  return (
    <DataContext.Provider
      value={{
        config,
        setConfig,
        token,
        setToken,
        conversaciones,
        setConversaciones,
        mensajes,
        setMensajes,
        // websocket,
        // setWebsocket,
        setWidgetState,
        ultimoMsje,
        setUltimoMsje,
        ultimaConv,
        setUltimaConv,
        handleWidgetClick,
        widgetState,
        saveConverLocalStorage,
        widgetStateView,
        handleConversations,
        showHelp,
        handleCloseHelp,
        getUSER,
        getCONVERSEL,
        saveConversations,
        soundActive,
        setSoundActive,
        usuario,
        ingresoUsuario,
        cerrarSesion,
        // mensajes,
        getCONFIG,
        agregarMensajes,
        cargarMensajes,
        grabarUltimoMsg,
        cargarConversaciones,
        eliminarConversaciones,
        conversacion_setAsRead,
        cantContNewMsg,
        subscribeNewMessages,
        newMsgReceived,
        setNewMsgReceived,
        isMobile,
        otherChannelInfo,
        setOtherChannelInfo,
        openWidgetContainer,
        mustGrow,
        setMustGrow,
        actualLocation,
        setActualLocation,
        loadingSpinner,
        setLoadingSpinner,
        openModalImg,
        setOpenModalImg,
        imgModal,
        setImgModal,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
DataProvider.propTypes = {
  children: PropTypes.node,
};
export default DataProvider;
