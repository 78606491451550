import React, {useEffect, useState, useContext} from 'react';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import Grow from '@material-ui/core/Grow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faSignLanguage, faHandPaper, faInfoCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { DataContext } from '../Context/WebChatContext';
import {Badge} from '@material-ui/core';
import {styled} from '@material-ui/styles'
import './Widget.css'

const Widget = () => {

 
  const root = document.documentElement;

  const {
    config,
    showHelp,
    handleCloseHelp,
    // handleWidgetClick,
    cantContNewMsg,
    openWidgetContainer
  } = useContext(DataContext);

  root.style.setProperty('--widgetContainer', config.colorFondo);
  root.style.setProperty('--widgetContainer2', config.colorFondo + 'f0');
  root.style.setProperty('--colorLetra', config.colorLetra);
  // console.log("from Widget",config)
  
  
  const [timeRender, setTimeRender] = useState(false);
  useEffect(() => {
    if (config.callout){
      setTimeout(() => {setTimeRender(true)}, config.timer*1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleWidget = (e) => {
  //   handleWidgetClick()    
  //   handleCloseHelp(e)
  // }
  const closeCallout = (e) => {
    handleCloseHelp(e);
    if (config.disenoWidget === "circle") {
      window.parent.postMessage({ action: "calloutClosed" }, "*");
    }
    if (config.disenoWidget === "circleText") {
      window.parent.postMessage({ action: "calloutClosedText" }, "*");
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 15,
      top: 25,
      padding: '0 4px',
    },
  }));
  return (
    <>
      <div
        style={
          config.posicion === "left"
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: 15,
                marginBottom: 15,
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginRight: 15,
                marginBottom: 15,
              }
        }
      >
        {config.callout && showHelp ? (
          timeRender ? (
            <Grow in={true}>
              <div
                id="LogoCallout"
                className="Callout"
                onClick={openWidgetContainer}
                style={{ cursor: "pointer" }}
              >
                <div className="auxCallout">
                  {config.iconoCallout === "question" ? (
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  ) : config.iconoCallout === "info" ? (
                    <FontAwesomeIcon icon={faInfoCircle} />
                  ) : config.iconoCallout === "bot" ? (
                    <FontAwesomeIcon icon={faRobot} />
                  ) : config.iconoCallout === "hands" ? (
                    <FontAwesomeIcon icon={faSignLanguage} />
                  ) : config.iconoCallout === "hand" ? (
                    <FontAwesomeIcon icon={faHandPaper} />
                  ) : config.iconoCallout ? (
                    <img
                      src={config.iconoCallout}
                      alt={config.iconoCallout}
                      width="32px"
                      height="32px"
                    />
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px",
                    }}
                  >
                    <span style={{ fontSize: "20px" }}>
                      {config.tituloCallout}
                    </span>
                    <span style={{ fontSize: "12px" }}>
                      {config.mensajeCallout}
                    </span>
                  </div>
                  <div
                    className={
                      config.posicion === "right"
                        ? "closeCallout"
                        : "closeCalloutLeft"
                    }
                  >
                    <CloseIcon onClick={(e) => closeCallout(e)} />
                  </div>
                </div>
              </div>
            </Grow>
          ) : null
        ) : null}
        {config.disenoWidget === "circle" ? (
          <div onClick={openWidgetContainer} className="widgetStyle">
            <Badge
              badgeContent={cantContNewMsg}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              overlap="rectangular"
            >
              {/* <img src='/img/speech-balloon.png' alt='' width={'48px'} height={'48px'}/> */}
              <ChatIcon
                style={{
                  marginTop: "18px",
                  marginLeft: "15px",
                  fontSize: "40px",
                }}
              />
            </Badge>
          </div>
        ) : (
          <div className="widgetStyleText" onClick={openWidgetContainer}>
            <StyledBadge
              overlap="rectangular"
              badgeContent={cantContNewMsg}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <ChatIcon style={{ marginTop: "10px", fontSize: "40px" }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "200px",
                }}
              >
                <div
                  style={{
                    marginLeft: "5px",
                    fontSize: "25px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {config.tituloWidget}
                </div>
                <div
                  style={{
                    marginLeft: "5px",
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {config.subtituloWidget}
                </div>
              </div>
            </StyledBadge>
          </div>
        )}
      </div>
    </>
  );
}

export default Widget;