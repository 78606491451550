import "./App.css";
import React, { useEffect, useContext } from "react";
import "react-chat-elements/dist/main.css";
// import axios from 'axios';

import Widget from "./components/Widget";
import WidgetContainer from "./components/WidgetContainer";
import { DataContext } from "./Context/WebChatContext";
import { MESSAGE } from "./functions/classes";
import { Howl } from "howler"; //sonido
import SoundNotification from "./audio/chatNotification.wav";
import moment from "moment";


function App() {
  //from context provider
  const {
    config,
    setConfig,
    token,
    setToken,
    widgetState,
    setWidgetState,
    getCONFIG,
    usuario,
    cargarConversaciones,
    ultimaConv,
    subscribeNewMessages,
    newMsgReceived,
    soundActive,
    conversacion_setAsRead,
    isMobile,
    conversaciones,
    actualLocation
    // agregarMensajes
  } = useContext(DataContext);

  // ** hacer la funcion que obtenga el valor del token en la URL **

  let url = window.location.href;
  let tokenurl = url.split("=")[1];
  // console.log(tokenurl)

  // console.log("widgetState  ",widgetState)

  const m1 = new MESSAGE();
  m1.type = "100";
  // m1.
  // console.log('m1',m1)
  useEffect(() => {
    moment.locale(navigator.language);
  }, []);

  useEffect(() => {
    if (token == null || usuario.uid == null) return;

    subscribeNewMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, usuario]);

  const sound = new Howl({
    src: SoundNotification,
  });

  useEffect(() => {
    if (newMsgReceived == null) return;

    //console.log('newMsgReceived received; sound')

    if ((soundActive && ultimaConv == null) || !actualLocation) {
      let index = conversaciones.findIndex(
        (c) => c.idConvTrii === newMsgReceived.idConvTrii
      );
      if (index >= 0) {
        sound.play();
      }
    }
    if (
      soundActive &&
      ultimaConv != null &&
      ultimaConv.idConvTrii !== newMsgReceived.idConvTrii
    ) {
      let index = conversaciones.findIndex(
        (c) => c.idConvTrii === newMsgReceived.idConvTrii
      );
      if (index >= 0) {
        let index = conversaciones.findIndex(
          (c) => c.idConvTrii === newMsgReceived.idConvTrii
        );
        if (index >= 0) {
          sound.play();
        }
      }
    } else {
      sound.mute();
    }

    //console.log(ultimaConv)
    if (ultimaConv != null) {
      conversacion_setAsRead(ultimaConv.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMsgReceived]);

  useEffect(() => {
    if (token == null || usuario.uid == null) return;

    cargarConversaciones();
    // let res = JSON.parse(localStorage.getItem('sound'));
    // if (res != null) setSoundActive(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, usuario]);

  useEffect(() => {
    if (tokenurl === undefined) {
      return;
    }
    setToken(tokenurl);
    // console.log('tokenurl', tokenurl)
    try {
      let res = JSON.parse(localStorage.getItem(`widgetState_${token}`));
      // console.log(resShow);
      // console.log('resIdConv',res);
      if (res === 0) {
        setWidgetState(0);
      }
      
      // if (res > 0 ) {
      //   console.log(widgetState)
      //   setWidgetState(res);
      // }

      if (res > 0) {
        if (widgetState === 2) {
          setWidgetState(2);
        } else if (widgetState === 3) {
          // console.log(ultimaConv)
        
          setWidgetState(3);
        } else if (widgetState === 4) {
          setWidgetState(4);
        } else {
          setWidgetState(1);
        }
      } 
      // else {
      //   closeTriiWidget();
      // }
      async function fetchConfig() {
        let response = await getCONFIG(tokenurl); // await APIgetConfig(tokenurl);

        if (response) {
          setConfig(response);
        }
      }
      // console.log('response',response)
      fetchConfig();
    } catch {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetState,token]);

  //Inicio eventos para cambiar tamaño del iframe
  const openTriiWidget = () => {
    if (config !== null) {
      // console.log(isMobile)
      if (isMobile) {
        window.parent.postMessage(
          {
            action: "openTriiWidget",
            alto: `${config.widgetAltoMobile ? config.widgetAltoMobile : 0}`,
          },
          "*"
        );
      } else {
        window.parent.postMessage({ action: "openTriiWidget" }, "*");
      }
    }
    // window.parent.postMessage({action: 'openTriiWidget'}, '*');
    // console.log('prueba')
  };

  const closeTriiWidget = () => {
    if (config !== null) {
      if (!config.callout) {
        if (config.disenoWidget === "circle") {
          window.parent.postMessage({ action: "closeTriiWidget" }, "*");
        } else if (config.disenoWidget === "circleText") {
          window.parent.postMessage({ action: "closeTriiWidgetText" }, "*");
        }
      }
      if (config.callout) {
        window.parent.postMessage({ action: "closeTriiWidgetCallout" }, "*");
      }

      if (config.mostrarWidget === false) {
        window.parent.postMessage({ action: "ocultar" }, "*");
      }
    }
  };

  const moveToLeft = () => {
    window.parent.postMessage({ action: "moveToLeft" }, "*");
  };

  const moveToRight = () => {
    window.parent.postMessage({ action: "moveToRight" }, "*");
  };
  //Fin eventos para cambiar tamaño del iframe

  // //test lo que devuelve save de webchat
  // let configJSON = {
  //   mostrarWidget: true,
  //   colorFondo: "#2a6ac1",
  //   colorLetra: "#ffffff",
  //   posicion: "right",
  //   disenoWidget: "circle",
  //   callout: false,
  //   timer: 0,
  //   tituloWidget: "solucion",
  //   subtituloWidget: "",
  //   logo: true,
  //   logoURL: "/assets/img/avatars/Sergey-Azovskiy.jpg",
  //   tituloHome: "trii home title",
  //   subtituloHome: "soluciones description",
  //   multipleConv: false,
  //   formPreChat: true,
  //   politicaDatos: true,
  //   politicaDatosLink: "https://trii.com.ar/",
  //   politicaDatosDesc: "tesxt descripcion politica dato",
  //   habilitarEncuesta: true,
  //   encuesta: 20188,
  //   tituloEmailPreChat: "Ingrese titulo para campo email aqui",
  //   tituloNombrePreChat: "Ingrese titulo para campo nombre aqui",
  // }

  // const getRandomInt = (min, max) => {
  //   return Math.floor(Math.random() * (max - min)) + min;
  // }

  //   useEffect(() => {
  //     // console.log('ultimoMsje change')
  //     // if ((ultimaConv != null) && (ultimaConv.id === ultimoMsje.idConv)){
  //     //   setMensajes(mensajes => [...mensajes, ultimoMsje]);
  //     // }

  // let grabar=false

  // conversaciones.forEach(conver => {
  //       if (conver.triiId == ultimoMsje.idConv){
  //         console.log('ultimoMsje change map conver')

  //         conver.lastMessage = ultimoMsje.text;
  //         grabar=true
  //       }
  //     })

  //     if(grabar){
  //       saveConversations(conversaciones);
  //     }

  //   }, [ultimoMsje]);

  // useEffect(() => {
  //   closeTriiWidget();

  // }, [])

  useEffect(() => {
    if (config !== null) {
      config.posicion === "right" ? moveToRight() : moveToLeft();
      config.callout && window.parent.postMessage({ action: "initCallout"}, "*");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config !== null ? config.posicion : null]);

  useEffect(() => {
    try {

      if (config == null) {
        return;
      }        
      else{
        if (config.mostrarWidget === false) {
          window.parent.postMessage({ action: "ocultar" }, "*");
        }
        if (config.disenoWidget === "circleText") {
          window.parent.postMessage({ action: "circleText" }, "*");
        }
        if (config.disenoWidget === "circle") {
          window.parent.postMessage({ action: "circle" }, "*");
        }
        // if (config.callout) {
        //   window.parent.postMessage({ action: "closeTriiWidgetCallout" }, "*");
        // }
  
        if (config.callout) {
          window.parent.postMessage({ action: "closeTriiWidgetCallout" }, "*");
        } else {
          window.parent.postMessage({ action: "closeTriiWidget" }, "*");
        }
      }
    } catch {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (widgetState !== 0) {
      // console.log(isMobile)
      if (isMobile) {
        window.parent.postMessage(
          {
            action: "openTriiWidget",
            alto: `${config.widgetAltoMobile ? config.widgetAltoMobile : 0}`,
          },
          "*"
        );
      } else {
        window.parent.postMessage({ action: "openTriiWidget" }, "*");
      }
    }
  }, [config]);

  useEffect(() => {
    widgetState !== 0 ? openTriiWidget() : closeTriiWidget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //  if (widgetState >= 0) {
  //     window.parent.postMessage({ action: "openTriiWidget" }, "*");
  //   }
  //   console.log("pruebaRender", widgetState)

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [widgetState])
  // console.log("showInit", widgetState)
  return (
    <>
      {config !== null && config.mostrarWidget && usuario.uid !== null ? (
        <div
          className={
            config.posicion === "left" ? "growPhoneLeft" : "growPhoneRight"
          }
        >
          {widgetState !== 0 ? <WidgetContainer /> : <Widget />}
        </div>
      ) : null}
    </>
  );
}

export default App;
