import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from '@material-ui/icons/Facebook';
// import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

import {Link} from "@material-ui/core";
import PropTypes from 'prop-types';

const ChatItemPers = (props) => {
  // console.log("props", props);
  return (
    <Link
      href={props.tipo === 3? `tel:${props.props.Numero}` : props.hreference + props.props.Numero}
      target="_blank"
      rel="noopener"
      underline="none"
      color="inherit"
    >
      <div className="rce-container-citem rce-container-citem-channelInfo">
        <div className="rce-citem">
          <div className="rce-citem-avatar">
            <div className="rce-avatar-container circle large">
              {props.tipo === 1 && (
                <img
                  alt="Icono/Foto de perfil"
                  // src="https://triis3.s3.sa-east-1.amazonaws.com/public/userImgProfile/1f5f0dbc-fbc4-4baf-bbb0-1821a732d910/imageprofile.png"
                  src={
                    props.props.Foto !== ""
                      ? props.props.Foto
                      : "img/profileDefault.jpg"
                  }
                  className="rce-avatar"
                />
              )}
              {(props.tipo === 2 || props.tipo === 3) && (
                <img
                  alt="Icono/Foto de perfil"
                  // src="https://triis3.s3.sa-east-1.amazonaws.com/public/userImgProfile/1f5f0dbc-fbc4-4baf-bbb0-1821a732d910/imageprofile.png"
                  src={
                    props.props.Foto !== ""
                      ? props.props.Foto
                      : "img/buildingDefault.png"
                  }
                  className="rce-avatar"
                />
              )}
            </div>
          </div>
          <div className="rce-citem-body">
            <div className="rce-citem-body--top" style={{width: "85%"}}>
              <div className="rce-citem-body--top-title">
                {props.props.Titulo}
              </div>
              <div className="rce-citem-body--top-time">
                {props.tipo === 1 && (
                  <WhatsAppIcon className="chat-icon-right iconChannelInfo" style={{color:"#4bc659"}}></WhatsAppIcon>
                )}
                {props.tipo === 2 && (
                  <FacebookIcon className="chat-icon-right iconChannelInfo" style={{color:"#1877f2"}}></FacebookIcon>
                )}
                {props.tipo === 3 && (
                  <PhoneAndroidIcon className="chat-icon-right iconChannelInfo" style={{color:"black"}}></PhoneAndroidIcon>
                )}
              </div>
            </div>

            <div className="rce-citem-body--bottom" style={{width: "90%"}}>
              <div className="rce-citem-body--bottom-title colorGrayO50">
                {props.props.Detalle}
              </div>
              <div className="rce-citem-body--bottom-tools"></div>
              <div className="rce-citem-body--bottom-tools-item-hidden-hover"></div>
              <div className="rce-citem-body--bottom-status"></div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

ChatItemPers.propTypes = {
  Foto: PropTypes.string,
  Numero: PropTypes.number,
  Detalle: PropTypes.string,
  tipo: PropTypes.number,
  Titulo: PropTypes.string
};
export default ChatItemPers;
