import React, {useContext} from 'react';
import Grow from '@material-ui/core/Grow';

//components
import Home from './Home';
import PreChat from './PreChat';
import Chat from './Chat';
import Encuesta from './Encuesta';


//context
import { DataContext } from '../Context/WebChatContext';

// const divStyle = {
//   width: '350px',
//   height: '480px',
//   backgroundColor: '#FDFFFC',
//   borderRadius: '12px',
//   boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',

// };



const WidgetContainer = () => {
  const {
    widgetState,
    isMobile,
    // mustGrow
  } = useContext(DataContext)




  return(
    <>
    <Grow in={true} >
      <div className={isMobile ? "divContPhone" : "divCont"}>
        {
          widgetState === 1 ? 
            <Home />:
          widgetState === 2 ? 
            <PreChat />:
          widgetState === 3 ? 
            <Chat  style={{transition: "width 0.1s, height 0.1s;"}}/>:            
          widgetState === 4 ? 
            <Encuesta />
            :null
        }
      </div>
    </Grow>
    </>
  );
}

export default WidgetContainer;