import React, {useContext,useState, useCallback} from 'react';
import {Box, IconButton, Button} from '@material-ui/core';

//material ui icons
import CloseIcon from '@material-ui/icons/Close';

import { DataContext } from '../Context/WebChatContext';
//spinner
import Spinner from './Spinner';


const Encuesta = () => {

  const {
    config, 
    // handleWidgetClick,
    // ultimaConv,
    setUltimaConv,
    // conversaciones,
    eliminarConversaciones,
    isMobile,  
    setWidgetState,
    token
  } = useContext(DataContext);

  
  const finalizarConversacion = useCallback(() => {
    //console.log("finalizar conversacion ", ultimaConv)
    
    eliminarConversaciones()
    
    setUltimaConv(null);
    
    
    
    
    localStorage.setItem(`widgetState_${token}`, 1); 
    setWidgetState(1);
  },[token])
  
 
  //console.log("ultima conv -->", ultimaConv);
  //console.log(conversaciones);
  //spinner
  const [spinnerNewChat, setSpinnerNewChat] = useState(true);
  const loadedIframe = () => {
    setSpinnerNewChat(false);
  };
  return (
    <>
    {spinnerNewChat ? (
      <Spinner mensaje="Cargando encuesta..."></Spinner>
    ): null}
      <Box bgcolor={config.colorFondo} borderRadius={isMobile?"unset"  : "12px 12px 0 0"}>
        <Box display="flex">
          <IconButton
            onClick={finalizarConversacion}
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon style={{ color: config.colorLetra }} />
          </IconButton>
        </Box>
      </Box>
      <Box textAlign="center" marginTop={4} marginX={2}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={isMobile? "messageBox_Encuesta_Phone" : ""}
        >
          <iframe
            title="encuesta"
            src={config.encuestaUrl}
            onLoad={loadedIframe}           
            className={isMobile? "messageBox_Encuesta_Iframe_Phone" : "messageBox_Encuesta_Iframe"}
          />
        </Box>
        <Button
          style={{
            width: "80%",
            position: "absolute",
            button: "20%",
            left: "10%",
            marginTop: "15px",
            color: config.colorLetra,
            backgroundColor: config.colorFondo,
          }}
          variant="contained"
          onClick={finalizarConversacion}
        >
          finalizar
        </Button>
      </Box>
    </>
  );
}
 
export default Encuesta;