import { Box } from "@material-ui/core";
import React from "react";
import './ConversationItem.css'
import moment from "moment";

const ConversationItem = (props) => {
    const feature = props
    const dateHour = moment(feature.date).locale(navigator.language).calendar(null, {
        sameDay: 'HH:mm',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD/MM/YYYY'
    });;
    return (
      <>
        <Box className="container-item" onClick={feature.onClick}>
          <Box className="item-content">
            <Box className="item-avatar">
              <Box className="item-avatar-content circle large">
                <img alt={feature.alt} src={feature.avatar} className="avatar" />
              </Box>
            </Box>
            <Box className="item-body">
              <Box className="item-body-top">
                <Box className="item-body-top-title">
                  {feature.title}
                </Box>
                <Box className="item-body-top-time">{dateHour}</Box>
              </Box>
              <Box className="item-body-bottom">
                <Box className="item-body-bottom-title">{feature.subtitle}</Box>
                <Box className="item-body-bottom-tools"></Box>
                <Box className="item-body-bottom-tools-item-hidden-hover"></Box>
                <Box className="item-body-bottom-status"></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
};

export default ConversationItem;
