import { createTheme } from '@material-ui/core/styles';

// let config = {
//   // text: '',
//   text: 'TRIIIIIIIIIIIIIIIIIIIIIII',
//   position: 'right',
//   bgcolor: '#E85D04',
//   colorText: 'white',
//   logo: true,
//   companyName: 'trii',
//   titulo: 'trii widget',
//   subtitulo: 'soluciones informaticas',
//   multiple: false,
//   form: true,
//   politicaDatos: false,
// }

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    },
  },
});

export default theme;
    