class MESSAGE {
    id: string;
    type: string;
    body: string;
    isMedia: boolean;
    
    // constructor(theName: string) {
    constructor(theName: string) {
      this.id = '';
      this.type = '';
      this.body = '';
      this.isMedia = false;
    }
    // move(distanceInMeters: number = 0) {
    //   console.log(`${this.name} moved ${distanceInMeters}m.`);
    // }
  }

 export {MESSAGE}