import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBkP4aYMDahzuECIWfC59N2fznxbf92Dys",
  authDomain: "triiclientem.firebaseapp.com",
  databaseURL: "https://triiclientem.firebaseio.com/",
  projectId: "triiclientem",
  storageBucket: "triiclientem.appspot.com",
  messagingSenderId: "526988685659",
  appId: "1:526988685659:web:3154c98cf7b8191c51ea7e",
  measurementId: "G-6KL43JXZF4"
};


// require('firebase/analytics')
// require('firebase/storage')

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// const analytics = firebase.analytics();
// const storage = firebase.storage();
//console.log('analytics',analytics)
//console.log('storage',storage)


const db = firebase.firestore()
const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider()

export {db, auth, provider}