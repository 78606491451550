import React, { useEffect, useRef, useContext, useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@material-ui/core";
import "./MessageBoxPers.css";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CheckIcon from "@material-ui/icons/Check";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment";
import WaveSurfer from "wavesurfer.js";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Spinner from "../Spinner";
import { DataContext } from "../../Context/WebChatContext";
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import { v4 as uuidv4 } from "uuid";


const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    paddingRight: 0,
    paddingLeft: "10px",
    fontSize: '13px',
    '&:focus': {
      background: 'unset !important',
    },
  }
}));
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const MessageBoxPers = (props) => {
  const [showPlay, setShowPlay] = useState(true)
  const {
    loadingSpinner,
    config,
    ultimaConv,
    usuario,
    token,
    agregarMensajes,
    grabarUltimoMsg,
     setOpenModalImg,
     setImgModal
  } = useContext(DataContext);
  // console.log(feature);
  const feature = props;
  if (!feature) {
    return;
  }
  
  const dateHour = moment(feature.date).format('LT');
  const classes = useStyles();
  useEffect(() => {
    console.log("ultimaConv", feature)

  }, [feature])
  

  // audio
  const waveformRef = useRef();
  let waveSurfer;
  useEffect(() => {
    // if (waveSurfer != null) {
    //   waveSurfer.destroy();
    // }
    let xhr = {
      cache: "default",
      mode: "cors", //ver si esta en modo cors o no-cors para que se dibuje el wavesurfer
      method: "GET",
      //origin: "firebasestorage.googleapis.com",
      //credentials: "same-origin",
      redirect: "follow",
      referrer: "client",
      // headers: [{ key: "Authorization", value: "my-token" },{ key: "Access-Control-Allow-Origin", value: "firebasestorage.googleapis.com" }],
      //headers: [{ key: "Access-Control-Allow-Origin", value: "*" }],
    };
    if (waveformRef.current) {
      waveSurfer = WaveSurfer.create({
        container: waveformRef.current,
        //container: '#waveFormId',
        xhr: xhr,
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        barMinHeight: 1,
        cursorWidth: 1,
        height: 50,
        progressColor: "#FE6E00",
        responsive: true,
        waveColor: "#C4C4C4",
        cursorColor: "transparent",
        minPxPerSec: 10,
        // media: audio
        // // audioContext: feature.data.audioURL
        
      });
      // console.log("audioURL",feature.data.audioURL)
      waveSurfer.on("error", function (e) {
        console.log("error", e)
        
      });
      waveSurfer.on("ready", function () {
        console.log("ready")
        
      });
      //waveSurfer.load('https://firebasestorage.googleapis.com/v0/b/triiclientem.appspot.com/o/29c4027b-2bae-46ec-8b4d-d0b072607ea9_f7d73118-05d0-48e1-a414-70d83d443eea%2Faudio1672498886815.ogg?alt=media&token=2f02d1ba-35df-41e0-b82d-2e405d9872ad');
      //waveSurfer.load("https://cdn.jsdelivr.net/gh/lunu-bounir/audio-equalizer/test/mono-8000hz.mp3?alt=media&token=f4c7d39b-e795-4945-a13c-3fdffdd1bb19");
      //waveSurfer.load("https://firebasestorage.googleapis.com/v0/b/triiclientem.appspot.com/o/test%2Fmono-8000hz.mp3?alt=media&token=f4c7d39b-e795-4945-a13c-3fdffdd1bb19");
      waveSurfer.load(feature.data.audioURL)
      waveSurfer.drawBuffer()
      
    }
  }, []);

  const playAudio = () => {
    console.log("waveformRef.current", waveformRef.current);
    setShowPlay(false);
    if (waveSurfer != null && waveSurfer) {
      waveSurfer.play();
      console.log("readyplay");
    }
  };
  const stopAudio = () => {
    console.log("waveformRef.current", waveformRef.current);
    setShowPlay(true);
    console.log("readystop");
    console.log("waveSurfer", waveSurfer);
    if (waveSurfer != null && waveSurfer) {
      waveSurfer.stop();
      console.log("readystop");
    }
  };

  const buttonMock = [
    {
      text: "OK",
    },
    {
      text: "Esta bien",
    },
    {
      text: "Dale",
    },
  ];
  const sendMessageRespButton = useCallback((textValue, event) => {
    if (ultimaConv == null) {
      return;
    }
    if (ultimaConv == null) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    // let textValue = $("#emojionearea1").data("emojioneArea").getText();
    
    // setLoadingSpinner(true)
    if (textValue !== "") {
      let mensaje = {
        msgId: uuidv4(),
        date: new Date(),
        idConv: ultimaConv.id,
        idConvTrii: ultimaConv.idConvTrii,
        type: "text",
        text: textValue,
        url: "",
        status: "sent",
        position: "right",
        userId: usuario.uid,
        token: token,
      };
      // $("#emojionearea1").data("emojioneArea").setText("");


      agregarMensajes(mensaje);
      grabarUltimoMsg(mensaje);

    }
    // setLoadingSpinner(false)

  },[ultimaConv]);

  const openModal = useCallback(() => {
    setOpenModalImg(true)
    setImgModal(feature.data.uri)
  }, [])

  return (
    <Box className="messages-container-mbox">
      <Box
        className={
          feature.position === "right" ? "boxSendMsg" : "boxRecieveMsg"
        }
      >
        <Box
          className={
            feature.position === "right"
              ? "messageBoxPersRight"
              : "messageBoxPersLeft"
          }
        >
          {/* Tipo de mensajes */}

          {/* Texto */}
          {feature.type === "text" && (
            <Box style={{ fontSize: "13px", wordBreak: "break-word", whiteSpace: "break-spaces" }}>
              {feature.text}

              {/* botones bot */}
              {feature.buttonsOb != null && (
              <Box className={classes.root}>
                {feature.buttonsOb.map((buttonX) => (
                  <Button
                    key={buttonX.title}
                    size="small"
                    variant="contained"
                    style={{
                      // fontSize: "11px",
                      padding: "0px",
                      // backgroundColor: `${config.colorFondo}`,
                      backgroundColor: "white",
                      textTransform: "inherit",
                    }}
                    onClick={(e) => sendMessageRespButton(buttonX.title, e)}
                  >
                    {buttonX.title}
                  </Button>
                ))}
              </Box>
              )}
              </Box>
          )}
          {/* audio */}
        
          {/* video */}
          {feature.type === "video" && (
            <Box style={{ height: "180px" }}>
              <video width="100%" height="100%" controls>
                <source type="video/mp4" src={feature.data.videoURL} />

                <source type="video/ogg" src={feature.data.videoURL} />
              </video>
            </Box>
          )}
          {/* Imagen */}
          {feature.type === "photo" && (
            <Box style={{ height: "180px", position: "relative", cursor: "pointer" }} onClick={openModal}>
              {feature.status === "updating" && <Spinner />}
              <img src={feature.data.uri} alt="" width="100%" height="100%" />
            </Box>
          )}
          {/* archivos */}
          {feature.type === "file" && (
            <Box className="fileBox" style={{ position: "relative" }}>
              {/* <Spinner style={{borderRadius: "7px", margin: "-7px -0px -7px -4px"}}/> */}
              {feature.status === "waiting" && (
                <Box
                  style={{
                    display: "flex",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: "rgb(253 253 253 / 50%)",
                    zIndex: "1",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "-6px 0px -7px -4px",
                    borderRadius: "7px",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <img
                src={`/img/FileIcons/ac3.png`}
                alt=""
                width="32px"
                height="32px"
              />
              <Typography variant="caption" style={{ marginLeft: "10px" }}>
                {feature.text}
              </Typography>
            </Box>
          )}

          {/* fecha hora - iconos envio */}
          <Box
            style={{
              display: "flex",
              fontSize: "11px",
              // color: "rgba(0, 0, 0, 0.45)",
              color:
                feature.position === "left" ? "rgba(0, 0, 0, 0.45)" : "#ffffff",
              justifyContent: "flex-end",
            }}
          >
            <Box>{dateHour}</Box>
            {feature.status !== "" && (
              <Box>
                {feature.status === "waiting" &&
                  feature.status !== "received" && (
                    <AccessTimeIcon style={{ fontSize: 15 }}></AccessTimeIcon>
                  )}
                {feature.status <= 0 && feature.status !== "received" && (
                  <AccessTimeIcon style={{ fontSize: 15 }}></AccessTimeIcon>
                )}
                {feature.status === "sent" && (
                  <CheckIcon style={{ fontSize: 15 }}></CheckIcon>
                )}
                {feature.status === 1 && (
                  <CheckIcon style={{ fontSize: 15 }}></CheckIcon>
                )}
                {feature.status === "received" && (
                  <DoneAllIcon style={{ fontSize: 15 }}></DoneAllIcon>
                )}
                {feature.status === 2 && (
                  <DoneAllIcon style={{ fontSize: 15 }}></DoneAllIcon>
                )}
                {feature.status == 3 && (
                  <CancelScheduleSendIcon
                    style={{ fontSize: 15 }}
                  ></CancelScheduleSendIcon>
                )}
                {feature.status === "read" && feature.status !== "received" && (
                  <DoneAllIcon
                    style={{ fontSize: 15, color: "blue" }}
                  ></DoneAllIcon>
                )}
                {feature.status >= 0 && feature.status !== "received" && (
                  <DoneAllIcon
                    style={{ fontSize: 15, color: "blue" }}
                  ></DoneAllIcon>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageBoxPers;
