import React, { useState, useEffect, useContext, useCallback } from "react";
import $ from "jquery";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Input,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { MessageBox } from "react-chat-elements"; //eslint-disable-line
import ReactScrollableFeed from "react-scrollable-feed";
// import SoundNotification from '../audio/chatNotification.wav';
// import { Howl } from 'howler'; //sonido  
// import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";

import Spinner from "./Spinner";
//react-pdf (oficial)
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "./MyDocument";

//context
import { DataContext } from "../Context/WebChatContext";

//material ui icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MenuIcon from "@material-ui/icons/Menu";
import SendIcon from "@material-ui/icons/Send";
import MicIcon from "@material-ui/icons/Mic";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
// import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

//sass
import './Chat.css';

//Modal image
import Modal from '@material-ui/core/Modal';

//zoom image
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import MessageBoxPers from "../components/Messages/MessageBoxPers"


window.jQuery = $;
require("emojionearea");



const Chat = () => {

  const {
    config,

    mensajes,
    setMensajes,
    setUltimaConv,
    ultimaConv,
    handleWidgetClick,
    widgetStateView,
    // getUSER,
    token,
    conversaciones,
    // setUltimoMsje,
    soundActive,
    setSoundActive,
    agregarMensajes,
    cargarMensajes,
    grabarUltimoMsg,
    usuario,
    isMobile,
    setWidgetState,
    eliminarConversaciones,
    setLoadingSpinner,
    openModalImg,
    setOpenModalImg,
    imgModal,
     setImgModal
  } = useContext(DataContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElfiles, setAnchorElfiles] = useState(null);
  const [errorFile, setErrorFile] = useState(false);
  const [AudioBox, setAudioBox] = useState(null);

  // console.log("send", $boxSend)
  // console.log(mensajes)
  // const [mensajeValue, setMensajeValue] = useState('');

  //Send websocket message
  // const sendToServer = (mensaje) => {
  //   if (!props.websocket) return;
  //   console.log('WebSocket send msg:', props.websocket);

  //   let user = getUSER();

  //   let sendMessage = {
  //     type: 'event',
  //     event: 'newMessage',
  //     dataType: 'json',
  //     data: {
  //       from: '',
  //       userId: user.sessionUser,
  //       converId: ultimaConv.id,
  //       triiconverId: ultimaConv.triiId,
  //       content: mensaje.text,
  //       token: token,
  //       msgId: mensaje.msgId,
  //       // info: newMessage.info
  //       info: {
  //         hub: 'webchat', //datos obtenido con el mensaje inicial al crear la conexion websocket
  //         converId: ultimaConv.id,
  //         triiconverId: ultimaConv.triiId,
  //         connectionId: '-oX4F4l5z67ZX62sktMFbgdf0ae5191', //datos obtenido con el mensaje inicial al crear la conexion websocket
  //         userId: user.sessionUser,
  //         domain: 'https://leandrooficina.trii.com.ar'
  //       }
  //     }
  //   }
  
  //   console.log('sendMessage: ', sendMessage);
  //   props.websocket.send(JSON.stringify(sendMessage));
  // }

  
  useEffect(() => {
    try {
      if (conversaciones == null || ultimaConv == null) return;
      conversaciones.forEach((c) => {
        if (ultimaConv != null && c.id === ultimaConv.id) {
          //console.log(c)
          setUltimaConv(c);
        }
      });
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversaciones]);
  useEffect(() => {
    setMensajes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // console.log('useEffect ultimaConv', ultimaConv)
    if (ultimaConv == null || ultimaConv.idConvTrii === 0) {
      //console.log('return')
      return;
    }
    localStorage.setItem(`ultimaConvIdConvTrii_${token}`, ultimaConv.idConvTrii);
    // let ultimaConvObj = JSON.parse(localStorage.getItem("idConver"));
    //emojionearea
    // console.log("idConverTrii", localStorage.getItem('ultimaConv'));  
    
   
    // $(document).ready(function() {
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ultimaConv,token]);
  useEffect(() => {
    if (ultimaConv == null || ultimaConv.idConvTrii === 0) {
      return;
    }
    $("#emojionearea1").emojioneArea({
      pickerPosition: "top",
      filtersPosition: "bottom",
            
      tonesStyle: "checkbox",
      filters: {
        flags: {
          icon: "flag_ar",
        },
      },
      events: {
        // keyup: function (editor, event) {
        //   console.log(editor.html());
        //   console.log(this.getText());
        // },
        keypress: function (editor, event) {
          // $('#emojionearea1').click();
          // $('#emojionearea1').focus();
          // setMensajeValue(this.getText());
          // $('#emojionearea1').data("emojioneArea").setText("");
          if (event.which === 13) {
            if (!event.shiftKey) {
              event.preventDefault();
              event.cancelBubble = true;
              this.hidePicker();
              // $('#emojionearea1').data("emojioneArea").this.search.blur();//para ocultar emoji picker
              // // conv_EnviarMsg(parseInt(msgViewModel.idconv()), parseInt(msgViewModel.idc())); funcion para enviar el mensaje
              //$('#emojionearea1').data("emojioneArea").this.getText()
              // setMensajeValue();
              onSend()
              // const texto = this.getText().trim();
              // if (texto !== "") {
              //   // let textValue = this.getText();
              //   // textValue = `${textValue}` //testing

              //   let mensaje = {
              //     msgId: uuidv4(),
              //     date: new Date(),
              //     idConv: ultimaConv.id,
              //     idConvTrii: ultimaConv.idConvTrii,
              //     type: "text",
              //     text: texto,
              //     url: "",
              //     status: "sent",
              //     position: "right",
              //     userId: usuario.uid,
              //     token: token,
              //   };
              //   agregarMensajes(mensaje);
              //   grabarUltimoMsg(mensaje);
              //   $("#emojionearea1").data("emojioneArea").setText("");
              // }
            }
          }
        },
      },
    });
  },[ultimaConv])
  const finalizarConversacionChat = useCallback(() => {
    // console.log("finalizar conversacion ", ultimaConv)

    eliminarConversaciones();
    setUltimaConv(null);
    localStorage.setItem(`ultimaConvIdConvTrii_${token}`, 0);
    setWidgetState(1);
  },[ultimaConv,token]);
  const verificarEncuesta = () => {
    if (config.habilitarEncuesta === true && config.encuestaUrl !== "") {
      widgetStateView(4);
      // localStorage.setItem("widgetState",4)
    } else {
      finalizarConversacionChat();
      // console.log("finalizado")
    }
  };
  useEffect(() => {
    if (ultimaConv != null && ultimaConv.idConvTrii != 0) {
      //console.log('sync messages')
      cargarMensajes();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ultimaConv]);
  const [timeOutInt, setTimeOutInt] = useState(0);
  useEffect(() => {
    //  console.log(ultimaConv)
    if (ultimaConv != null && ultimaConv.idConvTrii != 0 && timeOutInt > 0) {
      // clearTimeout(timeOutInt)
      cancelarTimeOut();
    } else if (
      ultimaConv != null &&
      ultimaConv.idConvTrii === 0 &&
      timeOutInt === 0
    ) {
      let Time = setTimeout(() => {
        timeOutBorrarConver();
      }, 30000);
      setTimeOutInt(Time);
      // console.log("timeOut", Time)
    }
  }, [ultimaConv, timeOutInt]);
  const timeOutBorrarConver = () => {
    // console.log("test")
    finalizarConversacionChat();
  };
  const cancelarTimeOut = useCallback(() => {
    if (timeOutInt > 0) {
      clearTimeout(timeOutInt);

      // console.log("clearTimeOut ", timeOutInt)
    }
  }, [timeOutInt]);
  //sonido de notificaciones
  // useEffect(() => {
  //   // if (soundActive)
  //   //   sound.play();

  //   // if (!soundActive)
  //   //   sound.mute();
  // }, [mensajes]);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuFiles = (event) => {
    setAnchorElfiles(event.currentTarget);
  };
  const handleCloseMenu = (option) => {
    if (option === "descargar") {
      //console.log('descargando...');
    }

    if (option === "sound") {
      localStorage.setItem(`sound_${token}`, !soundActive);
      setSoundActive(!soundActive);
    }
    setAnchorEl(null);
  };
  const handleCloseMenuFiles = () => {
    setAnchorElfiles(null);
  };
  const handleClose = () => {
    handleWidgetClick();
  };
  //subir y enviar archivo
  const handleFile = (event) => {
    if (ultimaConv == null) {
      return;
    }
    handleCloseMenuFiles();
    let file = event.target.files[0];
    //console.log('file ------------->', file)
    event.target.value = null;
    if (file) {
      if (file.size > 10485760) {
        return setErrorFile(true);
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        var storageRef = firebase.storage().ref();

        // Create a reference to 'mountains.jpg'
        var mountainsRef = storageRef.child(
          `${token}_${ultimaConv.id}/${file.name}`
        );
        let snapshot = await mountainsRef.put(file);
        let urlStorage = await snapshot.ref.getDownloadURL();
        //console.log('urlStorage', urlStorage)
        let mensaje = {
          msgId: uuidv4(),
          date: new Date(),
          idConv: ultimaConv.id,
          idConvTrii: ultimaConv.idConvTrii,
          type: "",
          text: file.name,
          url: urlStorage,
          status: "sent",
          position: "right",
          userId: usuario.uid,
          token: token,
          // uri: e.target.result
        };
        if (file.type.includes("image")) {
          mensaje.type = "photo";
        }

        if (file.type.includes("audio")) {
          mensaje.type = "audio";
        }
        if (file.type.includes("video")) {
          mensaje.type = "video";
        }

        if (file.type.includes("application") || file.type.includes("text")) {
          mensaje.type = "file";
        }
        agregarMensajes(mensaje);
        grabarUltimoMsg(mensaje);
      };
    }
  };
  const descargarArchivo = useCallback((mensaje) => {
    // if (mensaje.type !== 'file') return
    // const closeResizeIcon = <CloseIcon></CloseIcon>
    // let resizeClass = document.getElementsByClassName('rce-mbox-photo--img')
    // const divClose = React.createElement("div", {className:"imgCloseResizeMobile"})
    var a = document.createElement("a");
    if (mensaje.type === "file" ) {
      a.href = mensaje.url;
      // a.setAttribute('download', mensaje.text);
      a.setAttribute("target", "_blank");
      a.click();
    }
    // if (mensaje.type === "photo"){
    //   return (<TransformWrapper>
    //     <TransformComponent>
    //       <img src={mensaje.url} alt="test"/>
    //     </TransformComponent>
    //   </TransformWrapper>)
    // }
    if (mensaje.type === "photo" && isMobile) {
      setOpenModalImg(true);
      setImgModal(mensaje.url)

      // if (resizeClass === undefined) return;
      // for (var i = 0; i < resizeClass.length; i++) {
      //   resizeClass[i].className += " imgResizeMobile";
      //   resizeClass[i].appendChild(divClose);
      // }
    }
    if (mensaje.type === "photo" && !isMobile) {
      setOpenModalImg(true);
      setImgModal(mensaje.url)
      // var a = document.createElement("a");
      // a.href = mensaje.url;
      // // a.setAttribute('download', mensaje.text);
      // a.setAttribute("target", "_blank");
      // a.click();
    } else return;
  },[]);

  const modalImgClose = useCallback(() => {
    setOpenModalImg(false);
  }, []);
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorFile(false);
  };
  const handleBack = useCallback(() => {
    //console.log('setUltimaConv', null);
    setUltimaConv(null);
    localStorage.setItem(`ultimaConvIdConvTrii_${token}`, 0);
    widgetStateView(1);
  },[token]);
  const onSend = useCallback(() => {
    //console.log('APP, ultimaConv: ', ultimaConv)
    if (ultimaConv == null) {
      return;
    }
    let textValue = $("#emojionearea1").data("emojioneArea").getText();
    setLoadingSpinner(true)
    if (textValue !== "") {
      let mensaje = {
        msgId: uuidv4(),
        date: new Date(),
        idConv: ultimaConv.id,
        idConvTrii: ultimaConv.idConvTrii,
        type: "text",
        text: textValue,
        url: "",
        status: "sent",
        position: "right",
        userId: usuario.uid,
        token: token,
      };
      $("#emojionearea1").data("emojioneArea").setText("");

      // let response = await agregarMensajes(mensaje)
      // console.log("response", response);
      // if (response) {
      //   console.log("response", response);
      // }
      // setMensajeValue(mensaje)
      agregarMensajes(mensaje);
      grabarUltimoMsg(mensaje);
      // setMensajes(mensajes => [...mensajes, mensaje]);
      // sendToServer(mensaje);
    }
    setLoadingSpinner(false)

  },[ultimaConv]);
  //descargar conversacion (PDF)
  const handleDownloader = () => {
    handleCloseMenu();
    return;
  };
  //Caja de Audio
  const handleAudioBox = (event, recordState) => {
    //console.log(recordState);
    if (recordState === "start") {
      // rec_inicio = new Date();
      setIsRecording(true);
    }
    setAudioBox(event.currentTarget);
  };
  // const handleCloseAudioBox = () => {
  //   setAudioBox(null);
  // }
  //grabacion de audio
  const [isRecording, setIsRecording] = useState(false);
  const [timeRecord, setTimeRecord] = useState(0);
  useEffect(() => {
    if (isRecording === true) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        //console.log('getUserMedia supported.');
        navigator.mediaDevices
          .getUserMedia(
            // constraints - only audio needed for this app
            {
              audio: true,
            }
          )
          // Success callback
          .then(function (stream) {
            const mediaRecorder = new MediaRecorder(stream);
            let start = document.getElementById("startRecord");
            let send = document.getElementById("sendRecord");
            let cancel = document.getElementById("cancelRecord");
            //console.log(cancel);
            let isCanceled = false;
            if (start !== null) {
              setAnchorElfiles(null);
              if (mediaRecorder.state === "recording") return;
              mediaRecorder.start();
              //console.log("Estado de grabacion --> -", mediaRecorder.state);
            }

            if (send !== null) {
              send.onclick = () => {
                setAudioBox(null);
                stream
                  .getTracks() // get all tracks from the MediaStream
                  .forEach((track) => track.stop()); // stop each of them
                // mediaRecorder.stop();
                //console.log(mediaRecorder.state);
              };
            }

            if (cancel !== null) {
              cancel.onclick = () => {
                setAudioBox(null);
                if (mediaRecorder.state !== "inactive") {
                  stream
                    .getTracks() // get all tracks from the MediaStream
                    .forEach((track) => track.stop()); // stop each of them
                  // mediaRecorder.stop();
                }
                isCanceled = true;
              };
            }

            let chunks = [];

            mediaRecorder.ondataavailable = function (e) {
              chunks.push(e.data);
            };

            mediaRecorder.onstop = async function (e) {
              setTimeRecord(0);
              
              //console.log("recorder stopped");
              if (isCanceled) {
                //console.log("grabacion cancelada!");
                setIsRecording(false);
                return;
              }
              const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
              chunks = [];

              // const audioURL = window.URL.createObjectURL(blob);
              let reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = async function () {
                let base64String = reader.result;
                //console.log("Base64 String - ", base64String);
                var storageRef = firebase.storage().ref();

                var audioStorage = storageRef.child(
                  `${token}_${ultimaConv.id}/audio${new Date().getTime()}.ogg`
                );
                let snapshot = await audioStorage.putString(
                  base64String,
                  "data_url"
                );
                let urlStorage = await snapshot.ref.getDownloadURL();
                // console.log('urlStorage', urlStorage)
                let mensaje = {
                  msgId: uuidv4(),
                  date: new Date(),
                  idConv: ultimaConv.id,
                  idConvTrii: ultimaConv.idConvTrii,
                  type: "audio",
                  text: "",
                  url: urlStorage,
                  status: "sent",
                  position: "right",
                  userId: usuario.uid,
                  token: token,
                };
                agregarMensajes(mensaje);
                grabarUltimoMsg(mensaje);
              };
              isCanceled = false;
              setIsRecording(false);
            };;
          })

          // Error callback
          .catch(function (err) {
            console.log("The following getUserMedia error occurred: " + err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);
  //timer de grabacion de audio
  // let rec_inicio = null;
  useEffect(() => {
    let interval = null;
    if (isRecording) {
      interval = setInterval(() => {
        // new Date() - rec_inicio;
        // let tiempo = new Date() - rec_inicio;
        // console.log(tiempo)
        setTimeRecord((prevTime) => prevTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);
  //scss
  const root = document.documentElement;
  root.style.setProperty('--boxSend', config.colorFondo);
  root.style.setProperty('--boxSend2', config.colorFondo + 'ba');
  root.style.setProperty('--colorLetra', config.colorLetra);
  root.style.setProperty('--notchRight', config.colorFondo);
  console.log("isM;obile", isMobile)
  // root.style.setProperty('--boxReceive', day ? 'blue' : 'black');
  return (
    <>
      {ultimaConv == null || ultimaConv.idConvTrii === 0 ? (
        <Spinner mensaje="Creando conversación..."></Spinner>
      ) : null}
      {ultimaConv != null && (
        <>
          <Box
            bgcolor={config.colorFondo}
            color={config.colorLetra}
            borderRadius={isMobile ? "unset" : "12px 12px 0 0"}
            sx={{ background: `linear-gradient(${config.colorFondo}, #ffffff80)` }}
            paddingBottom={"5px"}
          >
            <Box display="flex">
              <Box style={{ marginTop: "7px" }}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon style={{ color: config.colorLetra }} />
                </IconButton>
              </Box>
              <Box marginX={1} marginTop={1}>
                <Box display="flex">
                  <img
                    src={
                      ultimaConv.agentImg !== ""
                        ? ultimaConv.agentImg
                        : "/img/waitingIcon.jpg"
                    }
                    alt={"logo"}
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                    }}
                  />
                  <span style={{ fontSize: "20px", marginLeft: "10px" }}>
                    {ultimaConv.agentName}
                  </span>
                </Box>
                <Box style={{ marginTop: "-5px" }}>
                  <span style={{ fontSize: "13px", marginLeft: "0px" }}>
                    {ultimaConv.title}
                  </span>
                </Box>
              </Box>
              <Box style={{ marginLeft: "auto", marginTop: "7px" }}>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenu}
                >
                  <MenuIcon style={{ color: config.colorLetra }} />
                </IconButton>
                <IconButton onClick={handleClose}>
                  <CloseIcon style={{ color: config.colorLetra }} />
                </IconButton>
              </Box>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                style={{ marginTop: "43px" }}
              >
                <MenuItem>
                  <PDFDownloadLink
                    key="downloadFile"
                    document={<MyDocument mensajes={mensajes} />}
                    fileName="WebChat Logs.pdf"
                    style={{ textDecoration: "none", color: "black" }}
                    onClick={handleDownloader}
                  >
                    <SystemUpdateAltIcon style={{ marginRight: "5px" }} />
                    Descargar conversacion
                  </PDFDownloadLink>
                </MenuItem>
                {soundActive ? (
                  <MenuItem onClick={() => handleCloseMenu("sound")}>
                    <VolumeUpIcon style={{ marginRight: "5px" }} />
                    Sonido activado
                  </MenuItem>
                ) : (
                  <MenuItem onClick={() => handleCloseMenu("sound")}>
                    <VolumeOffIcon style={{ marginRight: "5px" }} />
                    Sonido desactivado
                  </MenuItem>
                )}
                <MenuItem onClick={verificarEncuesta} style={{ color: "red" }}>
                  <CloseIcon style={{ marginRight: "5px", color: "red" }} />
                  Finalizar
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box
            // bgcolor="#E9ECEF"
            // bgcolor="white"
            overflow="hidden"
            id="messagesContent"
            className={isMobile ? "messageBox_Chat_Phone" : "messageBox_Chat"}
            paddingTop={"5px"}
            paddingBottom={"5px"}
          >
            <ReactScrollableFeed>
              {mensajes.map((mensaje) => (
                <MessageBoxPers
                  key={mensaje.msgId}
                  buttonsOb = {mensaje.buttonsOb}
                  // reply={{ (para responder mensajes)
                  //     photoURL: 'https://play-lh.googleusercontent.com/-VDgu-D7CCA0/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucki1NpsdC2wyOMXH9qpfL0k9KMg8Q/photo.jpg',
                  //     title: 'elit magna',
                  //     titleColor: '#8717ae',
                  //     message: 'Aliqua amet incididunt id nostrud',
                  // }}
                  date={mensaje.date.toDate()}
                  // onReplyMessageClick={() => console.log("reply clicked!")}
                  position={mensaje.position}
                  type={mensaje.type}
                  status={mensaje.status}
                  text={mensaje.text}
                  // style={{fontSize: "20px"}}
                  className={
                    mensaje.position === "left" ? "boxRecieveMsg" : "boxSendMsg"
                  }
                  style={{pointerEvents: "none"}}
                  data={{
                    audioURL: mensaje.url,
                    uri: mensaje.url,
                    videoURL: mensaje.url,
                    status: {
                      download: true,
                      click: false,
                      loading: 0,
                    },
                  }}
                  onClick={() => descargarArchivo(mensaje)}
                />
              ))}
            </ReactScrollableFeed>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: config.posicion,
            }}
            open={errorFile}
            autoHideDuration={4000}
            onClose={handleCloseToast}
            onClick={handleCloseToast}
          >
            <SnackbarContent
              style={{
                backgroundColor: "#D00000",
                position: "absolute",
                bottom: "350px",
                right: config.posicion === "right" ? "5px" : null,
              }}
              message={
                <>
                  <Box display="flex" alignItems="center">
                    <ErrorOutlineIcon
                      fontSize="default"
                      style={{ marginRight: "10px" }}
                    />
                    <span>
                      El tamaño del archivo debe ser menor a 10 megabytes.
                    </span>
                  </Box>
                </>
              }
            />
          </Snackbar>
          <Box className="containerChat">
            <Box style={{ display: "contents" }}>
              <textarea
                style={{
                  width: "100%",
                  border: "unset",
                  outline: "none",
                  resize: "none",
                }}
                className="myCustomCss"
                id="emojionearea1"
              ></textarea>
            </Box>
            <Box style={{ width: "60px", paddingLeft: "5px" }}>
              <IconButton
                style={{
                  padding: "0px",
                  marginLeft: "6px",
                  marginTop: "3px",
                  marginBottom: "7px",
                  color: config.colorFondo,
                }}
                aria-controls="simple-menu-files"
                aria-haspopup="true"
                onClick={handleMenuFiles}
                color={config.colorFondoo}
              >
                <MoreVertIcon />
              </IconButton>
              <IconButton
                style={{
                  padding: "0px",
                  marginLeft: "12px",
                  marginTop: "7px",
                  paddingLeft: "3px",
                  color: config.colorFondo,
                }}
                onClick={() => onSend()}
              >
                <SendIcon></SendIcon>
              </IconButton>
            </Box>
            <Menu
              id="simple-menu-files"
              anchorEl={anchorElfiles}
              keepMounted
              open={Boolean(anchorElfiles)}
              onClose={handleCloseMenuFiles}
              style={{ marginTop: "-65px" }}
            >
              <label htmlFor="idFileInput">
                <MenuItem
                  style={{
                    backgroundColor: config.colorFondo,
                    color: config.colorLetra,
                  }}
                >
                  <Input
                    id="idFileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFile}
                  />
                  <AttachFileIcon />
                </MenuItem>
              </label>
              <MenuItem
                style={{
                  backgroundColor: config.colorFondo,
                  color: config.colorLetra,
                }}
                aria-controls="audioRecBoxWebChat"
                aria-haspopup="true"
                id="startRecord"
                onClick={(e) => handleAudioBox(e, "start")}
                // onClick={() => setIsRecording(true)}
              >
                <MicIcon />
              </MenuItem>
            </Menu>
            <Menu
              id="audioRecBoxWebChat"
              anchorEl={AudioBox}
              keepMounted
              open={Boolean(AudioBox)}
              // onClose={handleCloseAudioBox}
            >
              <MenuItem style={{ color: "red" }} id="cancelRecord">
                <DeleteOutlineIcon></DeleteOutlineIcon>
              </MenuItem>
              <MenuItem>
                <FiberManualRecordIcon
                  style={{ marginRight: "10px", color: "red" }}
                />
                <span>
                  {("0" + Math.floor((timeRecord / 60000) % 60)).slice(-2)}:
                </span>
                <span>
                  {("0" + Math.floor((timeRecord / 1000) % 60)).slice(-2)}
                </span>
              </MenuItem>
              <MenuItem id="sendRecord">
                <SendIcon></SendIcon>
              </MenuItem>
            </Menu>
            <Modal
              open={openModalImg}
              // onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                backgroundColor: "#1f465e",
                color: "white",
                padding: "30px",
              }}
            >
              <div>
                <Box style={{ textAlign: "end", cursor: "pointer", marginTop: "-10px"}}>
                  <CloseIcon style={{fontSize: "32px"}} onClick={modalImgClose}></CloseIcon>
                </Box>
                <Box className="centerImageContainer">
                  <TransformWrapper>
                    <TransformComponent>
                      <img src={imgModal} alt="" style={{ width:"calc(100vw - 60px)", maxHeight:"calc(100vh - 150px)"}}/>
                    </TransformComponent>
                  </TransformWrapper>
                </Box>
                {/* <Box maxHeight="90vh" overflow="auto" paddingTop="20px">
                  <img src={imgModal} alt="" width="100%" />
                </Box> */}
              </div>
            </Modal>
          </Box>
        </>
      )}
    </>
  );
};

export default Chat;
