import React, {useState, useContext} from 'react';
import {Typography, Box, IconButton, TextField, Button} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../uiConfig';
import { v4 as uuidv4 } from 'uuid';
// import { APICrearConversacion } from '../functions/ApiClient';

//spinner
import Spinner from './Spinner';

//material ui icon
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//context
import { DataContext } from '../Context/WebChatContext';

const PreChat = () => {
  const {
    config,
    token,
    // getUSER,
    // conversaciones,
    setUltimaConv,
    handleWidgetClick,
    widgetStateView,
    handleConversations,
    saveConverLocalStorage,
    usuario,
    isMobile
  } = useContext(DataContext)

  theme.palette.primary.main = config.colorFondo;

  const [userNewChat, setUserNewChat] = useState({
    nombre: '',
    email: ''
  });

  //spinner
  const [spinnerNewChat, setSpinnerNewChat] = useState(false);


   //errores form
   const [error, setError] = useState({
    nombre: null,
    email: null,
  }) 
  //onChange en input para obtener dato y validar
  const handleChange = e => {
    let name = e.target.name
    let value = e.target.value
    setUserNewChat({...userNewChat, [e.target.name]: e.target.value});
    if (name === 'nombre'){
      if (value.length < 4){
        setError({
          ...error, nombre: true
        })
      }else{
        setError({
          ...error, nombre: false
        })
      }
    }
    if (name === 'email'){
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(value)){
        setError({
          ...error, email: true
        })
      }else{
        setError({
          ...error, email: false
        })
      }
    }
  }


  //crear conversacion
  const handleSendConver = async () => {
    //console.log(error)
    

    if (error.nombre === null && error.email === null) return setError({nombre: true, email: true});
    if (error.nombre === null && error.email !== null) return setError({nombre: true, email: false});
    if (error.nombre !== null && error.email === null) return setError({nombre: false, email: true});
    if (error.nombre === false && error.email === false) {
      
      setSpinnerNewChat(true);
      try {
        // let user = getUSER();
        //console.log('user', user)
        //console.log('user.sessionUser', user.sessionUser)
        let conversacion = {
          id: uuidv4(),
          idConvTrii: 0,
          date: new Date(),
          nombre: userNewChat.nombre,
          email: userNewChat.email,
          status: "new",
          agentName: "",
          agentImg: "",
          agentStatus: null,
          lastMessage: null,
          unread: 0,
          userId: usuario.uid,
          token: token,
          domain: "",
          title: "",
        }
        
        // let triiConverId = await APICrearConversacion(token, user.sessionUser, userNewChat.email, userNewChat.nombre, conversacion.id);
        // if (triiConverId == 0) {
        //   console.log('error al crear conversacion')
        //   setSpinnerNewChat(false);
        //   return;
        // }

        // console.log('triiConverId', triiConverId)
        // conversacion.triiId = triiConverId;

        console.log(conversacion.nombre, conversacion.email)

        setUltimaConv(conversacion)
        saveConverLocalStorage(conversacion);
        handleConversations(conversacion);

        setSpinnerNewChat(false);
        // localStorage.setItem("widgetState", 3);
        return widgetStateView(3);

      }
      catch {
        setSpinnerNewChat(false);
      }

    }
  }

  //volver a inicio
  const handleBack = () => {
    setUltimaConv(null);
    widgetStateView(1);

  }
  return (
    <>
      {spinnerNewChat ? <Spinner></Spinner> : null}
      <Box
        bgcolor={config.colorFondo}
        height={50}
        borderRadius={isMobile ? "unset" : "12px 12px 0 0"}
        textAlign="right"
      >
        <Box
          style={{
            marginTop: "7px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton onClick={handleBack}>
            <ArrowBackIcon style={{ color: config.colorLetra }} />
          </IconButton>
          <IconButton onClick={handleWidgetClick}>
            <CloseIcon style={{ color: config.colorLetra }} />
          </IconButton>
        </Box>
      </Box>
      <Box
        textAlign="center"
        margin={1}
        className={isMobile ? "messageBox_Prechat_Phone" : "messageBox_Prechat"}
      >
        <Box style={{overflow: "auto", maxHeigth: "50vh"}}>
          <Typography variant="body1" gutterBottom>
            Ingrese los datos correspondientes para iniciar un chat
          </Typography>
          <form style={{ marginTop: "10px", marginBottom: "10px" }}>
            <ThemeProvider theme={theme}>
              <TextField
                name="email"
                label={config.tituloEmailPreChat}
                variant="standard"
                onChange={handleChange}
                value={userNewChat.email}
                style={{ width: "80%" }}
                required
                error={error.email}
                helperText={error.email ? "introduzca un email valido." : null}
              />
              <TextField
                name="nombre"
                label={config.tituloNombrePreChat}
                variant="standard"
                onChange={handleChange}
                value={userNewChat.nombre}
                style={{ marginTop: "10px", width: "80%", color: "red" }}
                required
                error={error.nombre}
                helperText={
                  error.nombre
                    ? "introduzca un nombre con mas de 3 caracteres."
                    : null
                }
              />
            </ThemeProvider>
          </form>

          {config.politicaDatos ? (
            <>
              <Typography variant="body2" gutterBottom>
                {config.politicaDatosDesc}
              </Typography>
              <br />
              <Typography variant="body2" gutterBottom>
                <a
                  href={config.politicaDatosLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {config.politicaDatosLink}{" "}
                </a>
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
      <Box className="btnNuevoChat">
        <Button
          onClick={handleSendConver}
          variant="outlined"
          style={{
            backgroundColor: config.colorFondo,
            color: config.colorLetra,
            // margin: "10px 20px 0 20px",
          }}
        >
          INICIAR CHAT
        </Button>
      </Box>
    </>
  );
}
 
export default PreChat;