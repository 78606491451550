import React, {useContext} from 'react'
import ChatItemPers from "./ChatItemPers";
import CloseIcon from '@material-ui/icons/Close';
import { Scrollbars } from "react-custom-scrollbars";
import { DataContext } from "../../Context/WebChatContext";
import { Box } from "@material-ui/core";
import PropTypes from 'prop-types';



const PanelInfoChannels = (props) => {
    // console.log('otherPnael', props)
    const {
      isMobile,
      config,
      setOtherChannelInfo,
    } = useContext(DataContext);

    const closeChannel = () => {
      setOtherChannelInfo(0)
    }
    return (
      <Box
        className={
          isMobile
            ? config.logo
              ? "boxChatChannelsLogo_Phone"
              : "boxChatChannels_Phone"
            : !isMobile
            ? config.logo
              ? "boxChatChannelsLogo"
              : "boxChatChannels"
            : null
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 15px",
            margin: "10px 0px",
          }}
        >
          {props.panel === 1 && <div>WhatsApp</div>}
          {props.panel === 2 && <div>Facebook</div>}
          {props.panel === 3 && <div>Llamadas</div>}
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={closeChannel}
          ></CloseIcon>
        </div>
        <Scrollbars
          autoHide
          style={{ overflowY: "hidden", height: "89%" }}
          className={
            isMobile ? "messageBox_Scrollbars_Phone" : "messageBox_Scrollbars_1"
          }
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              className="track-horizontal"
              style={{ display: "none" }}
            />
          )}
          renderThumbHorizontal={(props) => (
            <div
              {...props}
              className="thumb-horizontal"
              style={{ display: "none" }}
            />
          )}
        >
          {props.panel === 1 &&
            config.link_WS.map((conversacion, index) => {
              // console.log("conver", conversacion);
              return (
                <ChatItemPers
                  style={{ background: "transparent !important" }}
                  props={conversacion}
                  hreference={isMobile ? `whatsapp://send?phone=`:`https://web.whatsapp.com/send?phone=`}
                  tipo={props.panel}
                  key={index}
                ></ChatItemPers>
              );
            })}
          {props.panel === 2 &&
            config.link_MG.map((conversacion, index) => {
              // console.log("conver", conversacion);
              return (
                <ChatItemPers
                  style={{ background: "transparent !important" }}
                  props={conversacion}
                  key={index}
                  tipo={props.panel}
                  hreference={
                    isMobile
                      ? `https://m.facebook.com/messages/compose?ids=`
                      : `https://m.me/`
                  }
                ></ChatItemPers>
              );
            })}
          {props.panel === 3 &&
            config.link_TEL.map((conversacion, index) => {
              // console.log("conver", conversacion);
              return (
                <ChatItemPers
                  style={{ background: "transparent !important" }}
                  props={conversacion}
                  key={index}
                  tipo={props.panel}
                ></ChatItemPers>
              );
            })}
        </Scrollbars>
      </Box>
    );
}
PanelInfoChannels.propTypes = {
  panel: PropTypes.number,
};
export default PanelInfoChannels