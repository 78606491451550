import React, { useState, useEffect, useContext } from "react";
import { ChatItem } from "react-chat-elements";
import { IconButton, Button, Box } from "@material-ui/core"; // eslint-disable-line
//material ui icons
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import CloseIcon from '@material-ui/icons/Close';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import SendIcon from '@material-ui/icons/Send';

// import { Scrollbars } from "react-custom-scrollbars";
import { v4 as uuidv4 } from "uuid";
// import { APICrearConversacion } from "../functions/ApiClient";
//context
import { DataContext } from "../Context/WebChatContext";
import Spinner from "./Spinner";
import PanelInfoChannels from "./Utils/PanelInfoChannels";
import ConversationItem from "./ConverstationItem/ConversationItem";


const Home = () => {
  const [spinnerNewChat, setSpinnerNewChat] = useState(false);


  const {
    config,
    token,
    // getUSER,
    conversaciones,
    // setConversaciones,
    setUltimaConv,
    setMensajes,
    handleWidgetClick,
    widgetStateView,
    handleConversations,
    saveConverLocalStorage,
    usuario,
    conversacion_setAsRead,
    isMobile,
    otherChannelInfo,
    setOtherChannelInfo,
  } = useContext(DataContext);
  // console.log("Form pre chat: --->", config.formPreChat);
  const initConversation = async () => {
    if (config.formPreChat) {
      widgetStateView(2);
      // localStorage.setItem("widgetState", 2);
      
    } else {
      setSpinnerNewChat(true);

      try {
        // let user = getUSER();
        // let dateTitle = new Date();

        // let day = dateTitle.getDate();
        // let month = dateTitle.getMonth() + 1;
        // let year = date.getFullYear();

        let conversacion = {
          id: uuidv4(),
          idConvTrii: 0,
          date: new Date(),
          nombre: null,
          email: null,
          status: "new",
          agentName: "",
          agentImg: "",
          agentStatus: null,
          lastMessage: null,
          unread: 0,
          userId: usuario.uid,
          token: token,
          domain: "",
          title: "",
        };
        // let idConverTrii = await APICrearConversacion(
        //   token,
        //   user.sessionUser,
        //   "",
        //   "",
        //   conversacion.id
        // );

        // if (idConverTrii == 0) {
        //   console.log("error al crear conversacion");
        //   setSpinnerNewChat(false);
        //   return;
        // }

        // console.log("triiConverId", idConverTrii);
        // conversacion.triiId = idConverTrii;

        //console.log('setUltimaConv', conversacion);
        setUltimaConv(conversacion);
        saveConverLocalStorage(conversacion);
        handleConversations(conversacion);
        // setSpinnerNewChat(false);              
        widgetStateView(3);
      } catch (error) {
        //console.log(error);
        setSpinnerNewChat(false);
      }
    }
  };

  // useEffect(() => {
  //   let convers = JSON.parse(localStorage.getItem("conversaciones"));
  //   console.log("init ---->", convers);
  //   if (convers !== null) {
  //     setConversaciones(convers);
  //   }
  // }, []);

  const redirectConver = React.useCallback((conversacion) => {
    // handleConver(conversacion)
    setMensajes([]);
    // console.log('setUltimaConv', conversacion);
    setUltimaConv(conversacion);
    conversacion_setAsRead(conversacion.id);
    widgetStateView(3);
    // localStorage.setItem('widgetState', 3)

  },[]);

  useEffect(() => {

    let getIdConver = localStorage.getItem(`ultimaConvIdConvTrii_${token}`);
    // let converId = conversaciones.findIndex(x => x.idConvTrii == getIdConver)
    // eslint-disable-next-line
    conversaciones.map((conversacion) => { 
      if (conversacion.idConvTrii == getIdConver) { //eslint-disable-line
        redirectConver(conversacion);
        // eslint-disable-next-line
      } else return;
    });
    // if (localState === 3) {
    // }
  }, [conversaciones, token]);

  const openChatListWS = () => {
    setOtherChannelInfo(1);
  };
  const openChatListMG = (e) => {
    e.preventDefault();
    setOtherChannelInfo(2);
  };
  const openChatListTEL = () => {
    setOtherChannelInfo(3);
  };
  // const openChannel = () => {
  //   setIsChannel(true)
  // }
  const Title = () => {
    return (
      <>
        <div style={{ maxWidth: "90%", width: "100%", overflow: "hidden" }}>
          <h1
            className={config.logo ? "titleConLogo" : "titleSinLogo"}
            style={{
              color: config.colorLetra,
            }}
          >
            {config.tituloHome != null ? config.tituloHome : "no title"}
          </h1>
        </div>
      </>
    );
  };
  return (
    <>
      {spinnerNewChat ? <Spinner></Spinner> : null}

      <Box
        bgcolor={config.colorFondo}
        borderRadius={isMobile ? "unset" : "12px 12px 0 0"}
        sx={{ background: `linear-gradient(${config.colorFondo}, #ffffff80)` }}
        minHeight={"200px"}
        // sx={{background: `linear-gradient(rgb(${config.colorFondo}), rgba(${config.colorFondo}, 0,5))`}}
        // style={{background: `linear-gradient(rgb(42, 106, 193), rgba(42, 106, 193, 0.5))`}}
        // style={{background: 'linear-gradient(rgb(42, 106, 193), rgba(42, 106, 193, 0.5))'}}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ paddingTop: "10px" }}
        >
          {config.logo ? (
            <img
              src={
                config.logoURL !== ""
                  ? config.logoURL
                  : "img/buildingDefault.png"
              }
              alt=""
              style={{
                maxWidth: "45px",
                maxHeight: "45px",
                margin: ".4rem",
                borderRadius: "50%",
                marginLeft: "25px",
              }}
            ></img>
          ) : (
            <Title />
          )}
          <IconButton
            disableRipple={true}
            onClick={handleWidgetClick}
            style={{ padding: "18px", paddingTop: "0" }}
          >
            <CloseIcon style={{ color: config.colorLetra }} />
          </IconButton>
        </Box>

        {config.logo ? <Title /> : null}
        <Box style={{ marginTop: "-10px", paddingBottom: "10px" }}>
          <div style={{ maxWidth: "90%", width: "100%", overflow: "hidden" }}>
            <h3
              style={{
                color: config.colorLetra,
                marginLeft: "25px",
                paddingBottom: "4px",
                marginBottom: "0",
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginTop: "5px",
              }}
            >
              {config.subtituloHome}
            </h3>
          </div>
        </Box>
      </Box>

      <Box minHeight='25px'>
      {((config.link_WS !== null && config.link_WS.length > 0) ||
        (config.link_MG !== null && config.link_MG.length > 0) ||
        (config.link_TEL !== null && config.link_TEL.length > 0)) && (
          <div className={isMobile ? "boxIconCanales_Phone" : "boxIconCanales"}>
            <p
              style={{
                margin: "0px",
                alignSelf: "center",
                fontSize: "13px",
                color: "gray",
                fontWeight: "400",
                marginRight: "15px",
                // marginTop: "-3px"
              }}
            >
              Contactanos por:{" "}
            </p>
            {config.link_WS.length > 0 && (
              // <Link
              //   // href={`https://wa.me/${config.link_WS}`}
              //   // target="_blank"
              //   // rel="noopener"
              //   onClick={openChatListWS}
              //   style={{ cursor: "pointer" }}
              //   title="Whatsapp"
              // >
              <WhatsAppIcon
                onClick={openChatListWS}
                style={{ cursor: "pointer", width: "22px", color: "#4bc659" }}
                title="Whatsapp"
              ></WhatsAppIcon>
              //   {/* <img
              //     src="img/WhatsApp_Logo_1_crop-removebg.png"
              //     className={isMobile ? "iconWS_Phone" : "iconWS"}
              //     alt="logo Whatsapp"
              //   /> */}
              // {/* </Link> */}
            )}
            {config.link_MG.length > 0 && (
              // <Link
              //   // href={`https://m.me/${config.link_MG}`}
              //   // target="_blank"
              //   // rel="noopener"
              //   onClick={openChatListMG}
              // >
              <FacebookIcon
                style={{ cursor: "pointer", color: "#1877f2" }}
                onClick={openChatListMG}
              ></FacebookIcon>
              // {/* <img
              //   src="img/f_logo_RGB-Blue_58.png"
              //   style={{
              //     marginLeft: "5px",
              //     cursor: "pointer",
              //     imageRendering: "-webkit-optimize-contrast",
              //   }}
              //   className={isMobile ? "iconMG_Phone" : "iconMG"}
              //   title="Facebook Messenger"
              //   alt="logo Facebook"
              // /> */}
              // </Link>
            )}
            {config.link_TEL.length > 0 && (
              // <Link
              //   // href={`#`}
              //   // target="_blank"
              //   // rel="noopener"
              //   onClick={openChatListTEL}
              // >
              <PhoneAndroidIcon
                // style={{ marginLeft: "5px", cursor: "pointer",color:"#61d97d" }}
                // className={
                //   isMobile ? "iconTubePhone_Phone" : "iconTubePhone"
                // }
                title="Llamada"
                style={{ cursor: "pointer", width: "20px" }}
                onClick={openChatListTEL}

                //  alt="icono telefono"
              ></PhoneAndroidIcon>
              // {/* <img
              //   src="img/1.png"
              //   style={{ marginLeft: "5px", cursor: "pointer" }}
              //   className={
              //     isMobile ? "iconTubePhone_Phone" : "iconTubePhone"
              //   }
              //   title="Llamada"
              //   alt="icono telefono"
              // /> */}
              // </Link>
            )}
          </div>
      )}
      </Box>
      <Box>
        {config.logo ? (
          <Box className={isMobile ? "messageBoxPhone" : "messageBox"}>
            {conversaciones.length === 0 ? (
              <p
                style={{
                  margin: "0 0 0 0px",
                  textAlign: "center",
                  fontSize: "13px",
                  color: "gray",
                  visibility: "hidden",
                }}
              >
                Mis Conversaciones
              </p>
            ) : (
              <p
                style={{
                  margin: "0 0 0 0px",
                  fontSize: "16px",
                  color: "gray",
                  marginLeft: "15px",
                  paddingTop: "10px",
                }}
              >
                Mis Conversaciones
              </p>
            )}
            <Box
              // style={{ overflowY: "hidden", padding: "30px 0px", maxHeight: "300px" }}
              className={
                isMobile
                  ? "messageBox_Scrollbars_Phone"
                  : "messageBox_Scrollbars"
              }
            >
              {conversaciones.map((conversacion) => {
                return (
                  // <ChatItem
                  //   key={conversacion.id}
                  //   avatar={
                  //     conversacion.agentImg === ""
                  //       ? "/img/waitingIcon.jpg"
                  //       : conversacion.agentImg
                  //   }
                  //   alt={conversacion.alt}
                  //   title={conversacion.title}
                  //   subtitle={conversacion.lastMessage}
                  //   date={conversacion.date.toDate()}
                  //   unread={conversacion.unread}
                  //   onClick={() => redirectConver(conversacion)}
                  //   style={{ background: "transparent !important" }}
                  // ></ChatItem>
                  <ConversationItem
                    key={conversacion.id}
                    avatar={
                      conversacion.agentImg === ""
                        ? "/img/waitingIcon.jpg"
                        : conversacion.agentImg
                    }
                    alt={conversacion.alt}
                    title={conversacion.title}
                    subtitle={conversacion.lastMessage}
                    date={conversacion.date.toDate()}
                    unread={conversacion.unread}
                    onClick={() => redirectConver(conversacion)}
                    style={{ background: "transparent !important" }}
                  ></ConversationItem>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Box
            className={
              isMobile ? "messageBoxSinLogoPhone" : "messageBoxSinLogo"
            }
          >
            {conversaciones.length === 0 ? (
              <p
                style={{
                  margin: "0 0 0 0px",
                  textAlign: "center",
                  fontSize: "13px",
                  color: "gray",
                  visibility: "hidden",
                }}
              >
                Mis Conversaciones
              </p>
            ) : (
              <p
                style={{
                  margin: "0 0 0 0px",
                  textAlign: "center",
                  fontSize: "13px",
                  color: "gray",
                }}
              >
                Mis Conversaciones
              </p>
            )}
            <Box
              // autoHide
              // autoHeight
              // style={{ overflowY: "hidden" }}
              className={
                isMobile
                  ? "messageBox_Scrollbars_SinLogo_Phone"
                  : "messageBox_Scrollbars_SinLogo"
              }
            >
              {conversaciones.map((conversacion) => {
                return (
                  <ChatItem
                    key={conversacion.id}
                    avatar={
                      conversacion.agentImg === ""
                        ? "/img/waitingIcon.jpg"
                        : conversacion.agentImg
                    }
                    alt={conversacion.alt}
                    title={conversacion.title}
                    subtitle={conversacion.lastMessage}
                    date={conversacion.date.toDate()}
                    unread={conversacion.unread}
                    onClick={() => redirectConver(conversacion)}
                    style={{ background: "transparent !important" }}
                  ></ChatItem>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
      <Box
        // style={{ textAlign: "center", marginTop: "10px" }}
        className={isMobile ? "btnNuevoChat_Phone" : "btnNuevoChat"}
      >
        {(config.multipleConv && conversaciones.length < 5) ||
        (!config.multipleConv && conversaciones.length === 0) ? (
          <Button
            variant="contained"
            onClick={initConversation}
            style={{
              backgroundColor: config.colorFondo,
              color: config.colorLetra,
            }}
            startIcon={<SendIcon />}
          >
            Iniciar chat
          </Button>
        ) : (
          <Button disabled variant="contained">
            Iniciar chat
          </Button>
        )}
      </Box>
      {otherChannelInfo > 0 && (
        <PanelInfoChannels panel={otherChannelInfo}></PanelInfoChannels>
      )}
    </>
  );
};

export default Home;
