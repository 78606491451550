import * as React from 'react';
import {CircularProgress} from '@material-ui/core';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';


 function Spinner(props) {
   return (
     <Box
      
       sx={{
         display: "flex",
         width: "100%",
         height: "100%",
         background: "rgb(253 253 253 / 50%)",
         zIndex: "1",
         alignItems: "center",
         justifyContent: "center",
         flexDirection: "column",
         position: "absolute",
       }}
     >
       <CircularProgress
       />
       {props.mensaje != null && props.mensaje != null && (
         <span
           style={{
             marginTop: "20%",
           }}
         >
           {props.mensaje}
         </span>
       )}
     </Box>
   );
 }

 Spinner.propTypes = {
   mensaje: PropTypes.any,
 };
 export default Spinner;